import React from "react";

export const swedish = {
    'not_found_error':
        <React.Fragment>
            <h2>Prenumerationen kunde inte hittas</h2>
            <p><a href="mailto:post@ordrum.se">Kontakta oss</a> för att få hjälp med din prenumeration.</p>
        </React.Fragment>,
    'expired_error':
        <React.Fragment>
            <h2>Din prenumeration har gått ut</h2>
            <p><a href="mailto:post@ordrum.se">Kontakta oss</a> om du vill förlänga den.</p>
        </React.Fragment>,
    'unknown_error':
        <React.Fragment>
            <h2>Ett fel uppstod</h2>
            <p>$</p>
            <p><a href="mailto:post@ordrum.se">Kontakta oss</a> om du vill förlänga den.</p>
        </React.Fragment>,
    'observer_extra': <div>Extra text för observatör. Hur spelar man t.ex?</div>,
    'observer_link': 'Observatörslänk:',
    'page_title': 'Talarkortleken',
    'about_menu':
        <React.Fragment>
            <h2>Ordrum</h2>
            <p>Talarkortleken är framtagen av Ordrum (<a href={"https://www.ordrum.se/"} rel="noreferrer"
                                                         target={"_blank"}>https://www.ordrum.se/</a>), som är ett
                utbildningsföretag specialiserat på kommunikationsträning. Vi utbildar i presentationsteknik,
                bemötande
                och effektiva möten.</p>
            <h2>Det finns fler versioner</h2>
            <p>Talarkortleken finns i flera versioner som du hittar i Ordrums webbutik (<a
                href={"https://www.ordrum.se/butik"} rel="noreferrer" target={"_blank"}>https://www.ordrum.se/butik</a>). Skulle du
                vilja
                ha en egen version av Talarkortleken? Hör av dig så löser vi det.</p>

            <h2>Webbutiken</h2>
            <p>I Ordrums webbutik (<a href={"https://www.ordrum.se/butik"} rel="noreferrer"
                                      target={"_blank"}>https://www.ordrum.se/butik</a>) kan du köpa material och
                böcker
                för kommunikationsträning.</p>

            <h2>Kontakta oss</h2>
            <p>Har du frågor eller funderingar kring våra produkter? Maila oss på <a
                href={"mailto:post@ordrum.se"}>post@ordrum.se</a> eller ring <a href={"tel:031-7433440"}>031 743 34
                40</a></p>
        </React.Fragment>,
    'tutorial_base':
        <React.Fragment>
            <h2>Så här spelar du</h2>
            <p>Talarkortleken är en samling spelkort för att öva presentationsteknik. Spelkorten är uppdelade i olika
                kategorier. Klicka på de kort du vill använda. (Om du klickar en gång till tas kortet bort och du kan välja
                ett nytt kort.)</p>
            <p>Det går att spela kortleken utan att man förbereder sig men ni kan också komma överens om att man får en
                minut att förbereda sig.</p>
            <p>Tryck på cirkeln märkt "3 minuter” så räknar timern ned. När det är en halv minut kvar börjar timern
                blinka.</p>
        </React.Fragment>
    ,
    'tutorial_association':
        <React.Fragment>
            <h2>Så här spelar du</h2>
            <p><i>Talarkortleken Inspiration</i> är en samling fotografier som kan användas på många olika vis.</p>
            <p>Klicka på kortet så visas en bild och om du sedan klickar en gång till tas bilden bort.</p>
            <p>Om du vill använda timer så kan du trycka på cirkeln märkt ”2 minuter” så räknar timern ned. När det är en halv minut kvar börjar timern blinka.</p>
        </React.Fragment>
    ,
    'tutorial_evaluation':
        <React.Fragment>
            <b>Utvärdering</b><p>Deltagarna berättar – utifrån bilden de fått – om något de tar med sig från dagen.</p>
        </React.Fragment>
    ,
    'tutorial_exercise_1':
        <React.Fragment>
            <b>Berättarövning</b><p>Deltagarna berättar om en händelse – i sina egna liv – som de kommer att tänka på då de ser bilden.</p>
        </React.Fragment>
    ,
    'tutorial_exercise_2':
        <React.Fragment>
            <b>Skrivövning</b><p>Deltagarna skriver en påhittad berättelse om det som just kommer att hända efter ögonblicket bilden fångat.</p>
        </React.Fragment>
    ,
    'tutorial_audience':
        <React.Fragment>
            <p><b>Publik</b> – ger dig är en beskrivning av dem du talar till.</p>
        </React.Fragment>
    ,
    'tutorial_complication':
        <React.Fragment>
            <p><b>Komplikation</b> – ger dig en extra utmaning.</p>
        </React.Fragment>
    ,
    'tutorial_environment':
        <React.Fragment>
            <p><b>Lokal</b> – beskriver lokalen du talar i.</p>
        </React.Fragment>
    ,
    'tutorial_role':
        <React.Fragment>
            <p><b>Talare</b> – ger dig en roll eller ett perspektiv att tala utifrån.</p>
        </React.Fragment>
    ,
    'tutorial_observer':
        <React.Fragment>
            <p><b>Observatör</b> – ger dig instruktion till vad du ska ge talaren feedback på.</p>
        </React.Fragment>
    ,
    'tutorial_subject':
        <React.Fragment>
            <p><b>Ämne</b> – ger dig ämnet du ska tala kring.</p>
        </React.Fragment>
    ,
    'tutorial_feedback':
        <React.Fragment>
            <h2>Så här får du feedback</h2>
            <p>För att ge dig som talare en användbar feedback finns ett antal <b>Observatör</b>-kort, med
            instruktion på vad man ska ge feedback på. Varje person som ska ge feedback får ett unikt kort via
            QR-koden nedan. Deltagarna tittar på korten direkt i sina egna telefoner.</p>
        </React.Fragment>
    ,
    'scan_qr_code': <p>Scanna QR-koden med mobilen</p>,
    'email_body_default':
        "Välkommen till Talarkortleken!\n\nDin personliga länk för att spela: $"+
        "\n\n"+
        "Länken som kan användas av de i publiken som ska ge dig feedback: $"+
        "\n\n"+
        "Vänligen\nOrdrum"
    ,
    'email_body_association':
        "Välkommen till Talarkortleken!\n\nDin personliga länk för att spela: $"+
        "\n\n"+
        "Vänligen\nOrdrum"
    ,
    'email_subject': 'Talarkortleken',
    'how_to_button_text': 'Så här spelar du',
    'ordrum_link': "https://www.ordrum.se",
    'talarkortleken_url': "www.talarkortleken.se",
    'swedish': 'Svenska',
    'english': 'Engelska',
}