import React, {Component} from 'react';
import Utils from "../Utils";
import Message from "./Message";
import Button from "./Button";
import Form from "../form/Form";
import Select from "../form/Select";
import Date from "../form/Date";
import TextArea from "../form/TextArea";
import Text from "../form/Text";
import Submit from "../form/Submit";
import styles from "./Subscription.module.css";
import subscriptionsStyles from "./Subscriptions.module.css";
import ReactTable from 'react-table-6';
import "react-table-6/react-table.css";
import {getTranslation} from "../language/Language";

export default class SubscriptionDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isEditing: false,
            isError: false,
            message: null,
            details: null,
            showDetailedStatistics: false,
            statistics: {
                all_time: [],
                summary: []
            },
            decks: []
        }
    }
    load() {
        Utils.Fetch({
            action: 'load_subscription_details',
            id: this.props.ID,
        }).then( res => {
            let statistics = {
                all_time: [],
                summary: []
            };
            if (Array.isArray(res.statistics.all_time))
                statistics.all_time = res.statistics.all_time;
            if (Array.isArray(res.statistics.summary))
                statistics.summary = res.statistics.summary;
            Utils.language = res.subscription.language;
            this.setState({
                details: {...res.subscription},
                decks: this.props.baseData.decks.filter(d => d.language === res.subscription.language),
                statistics: statistics,
                isError: false,
                message: null,
            });
        }).catch(message => {
            this.setState({
                isError: true,
                message: message.toString(),
            });
        });

    }
    componentDidMount() {
        if (this.props.ID)
            this.load();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.ID !== prevProps.ID || Utils.language !== prevState?.details?.language)
            this.load();
    }

    onOpenForEditClick() {
        this.setState({
            isEditing: true,
            message: null,
        });
    }

    onSaveClick(data) {
        Utils.Fetch({
            action: 'save_subscription',
            id: this.props.ID,
            data
        }).then(() => {
            this.setState({
                isEditing: false,
                isError: false,
                message: 'Ändringarna har sparats',
            });
        }).catch(message => {
            this.setState({
                isError: true,
                message: message.toString(),
            });
        });
    }

    onArchiveClick() {
        Utils.Fetch({
            action: 'archive_subscription',
            id: this.props.ID,
        }).then(() => {
            this.setState({
                isEditing: false,
                isError: false,
                message: 'Prenumerationen har arkiverats',
            });
        }).catch(message => {
            this.setState({
                isError: true,
                message: message.toString(),
            });
        });
    }

    static getTranslatedEvent(value) {
        switch (value) {
            case 'open':
                return 'Öppna';

            case 'click_card_face':
                return 'Klick kort, framsida';

            case 'click_card_back':
                return 'Klick kort, textsida';

            case 'click_timer':
                return 'Klick timer';

            default:
                return value;
        }
    }

    static getTranslatedEventTarget(value) {
        switch (value) {
            case 'deck':
                return 'Kortlek';
            case 'observer':
                return 'Observationskort';
            case 'timer_start':
                return 'Start';
            case 'timer_stop':
                return 'Stopp';
            case 'timer_pause':
                return 'Paus';
            case 'category':
                return 'Kategori';
            default:
                return value;
        }
    }

    static getTranslatedEventTargetId(categories, value) {
        let c = categories.find(e => e.id === value);
        if (c)
            return c.text_swedish;
    }

    renderStatisticsSummary() {
        const columns_summary = [
            {
                Header: 'Händelse',
                accessor: 'event',
                Cell: props => <span>{SubscriptionDetails.getTranslatedEvent(props.value)}</span>
            }, {
                Header: 'Vad',
                accessor: 'event_target',
                Cell: props => <span>{SubscriptionDetails.getTranslatedEventTarget(props.value)}</span>
            }, {
                Header: 'Vad, typ',
                accessor: 'event_target_id',
                Cell: props => <span>{SubscriptionDetails.getTranslatedEventTargetId(this.props.baseData.categories, props.value)}</span>
            },
            {
                Header: 'Antal',
                accessor: 'count',
            }
        ];
        return <React.Fragment>
            <div className={subscriptionsStyles.TableTitle}>Statistik</div>
                <ReactTable
                    columns={columns_summary}
                    data={this.state.statistics.summary}
                    showPagination={false}
                    defaultPageSize={13}
                />
                </React.Fragment>
    }
    renderStatistics() {

        const columns_all_time = [
            {
                Header: 'Datum',
                accessor: 'create_date',
                defaultSortDesc: true,
            }, {
                Header: 'Händelse',
                accessor: 'event',
                Cell: props => <span>{SubscriptionDetails.getTranslatedEvent(props.value)}</span>
            }, {
                Header: 'Vad',
                accessor: 'event_target',
                Cell: props => <span>{SubscriptionDetails.getTranslatedEventTarget(props.value)}</span>
            }, {
                Header: 'Vad, typ',
                accessor: 'event_target_id',
                Cell: props => <span>{SubscriptionDetails.getTranslatedEventTargetId(this.props.baseData.categories, props.value)}</span>
            }
        ];
        return <React.Fragment>
                <div className={subscriptionsStyles.TableTitle}>Detaljerad statistik</div>
                <ReactTable
                    columns={columns_all_time}
                    data={this.state.statistics.all_time}
                    defaultPageSize={15}
                    showPageSizeOptions={false}
                    style={{marginBottom: '1rem'}}
                    />
            </React.Fragment>;
    }

    onLangChange(lang) {
        if(lang !== this.state.details.language)
            this.setState({ details: {...this.state.details, deck_id: null, language: lang}, decks: this.props.baseData.decks.filter(d => d.language === lang)})
    }

    render() {
        let bodyStr = "";
        let toStr = "";
        let subject = "";
        if (this.state.details) {
            if (this.state.details?.deck_id?.startsWith('association')) {
                bodyStr = getTranslation('email_body_association', this.state.details.language,this.state.details.full_url)
            } else {
                bodyStr = getTranslation('email_body_default', this.state.details.language,
                    this.state.details.full_url,
                    this.state.details.observer_url);
            }

            toStr = this.state.details.contact_info.trim();
            subject = getTranslation('email_subject',this.state.details.language);
        }

        return <React.Fragment>
                <div className={styles.Container}>
                {this.state.message ? <Message isError={this.state.isError}>
                    {this.state.message}
                </Message> : null}

                <Form data={this.state.details} onSubmit={data => this.onSaveClick(data)} isReadOnly={!this.state.isEditing}>
                    <Text name={'id'} title={'Prenumeration NR'} isReadOnly={true}/>
                    <Select onChange={(e) => {
                        this.onLangChange(e);
                    }}
                            name={'language'}
                            title={'Språk'}
                            notSelectedText={'-- Välj språk --'}
                            options={this.props.baseData.decks.filter((value, index, array) => {
                                return array.findIndex( value2 => value.language === value2.language) === index;
                            }).map(deck => ({value: deck.language, text: deck.language}))}/>
                    <Select name={'deck_id'}
                            title={'Kortlek'}
                            notSelectedText={'-- Välj kortlek --'}
                            options={this.state.decks.map(deck => ({value: deck.id, text: deck.name}))}/>
                    <Date name={'create_date'} title={'Skapad'} isReadOnly={true}/>
                    <Date name={'expire_date'} title={'Giltig till och med'}/>
                    <TextArea name={'comment'} title={'Kommentarer'}/>
                    <Text name={'contact_name'} title={'Kontaktperson'}/>
                    <Text name={'contact_info'} title={'E-postaddress'}/>
                    <Text name={'full_url'} title={'Länk'} isReadOnly={true}/>
                    {!this.state.details?.deck_id?.startsWith('association') ?
                        <Text name={'observer_url'} title={'Observatörslänk'} isReadOnly={true}/>
                        : null}
                    {!this.state.isEditing ?
                        <Button title={'Arkivera'} onClick={() => this.onArchiveClick()}/>
                        : null}
                    {this.state.isEditing ?
                        <Submit title={'Spara ändringar'}/>
                        :
                        <Button title={'Redigera'} onClick={() => this.onOpenForEditClick()}/>
                    }
                    <Button title={'Stäng'} onClick={() => this.props.onClose()}/>
                    <Button title={this.state.showDetailedStatistics === false ? 'Mer statistik' : 'Dölj statistik'} onClick={() => this.setState({showDetailedStatistics:!this.state.showDetailedStatistics})}/>
                    <Button title={"Maila länk"} onClick={() => window.location.href = `mailto:${toStr}?subject=${subject}&body=${encodeURIComponent(bodyStr)}`}/>

                </Form>

            {/* Är detta död kod? */}
            {this.props.onCloseClick ? <Button title={'Stäng'} onClick={e => this.props.onCloseClick(e)}/> : null}
            </div>
            <div className={styles.StatisticsContainer}>
                {this.renderStatisticsSummary()}
            </div>
            {this.state.showDetailedStatistics === true ? this.renderStatistics() : null}
        </React.Fragment>
    }
}